<template>
  <body style="position: relative;">
  <div id="wrap">
		<section>
			<headerLayout></headerLayout>
		</section>
     <main>
        <section class="sub-visual">
            <div class="innWrap">
                <div class="txt">
                    <h2>내게 맞는 상품</h2>
                    <!-- <p>국내 최대 용량의 기업전용망을 통한 고품질 인터넷 전용회선 서비스</p> -->
                </div>
                <div class="location">
                    <ul>
                        <li><a href="#">SUPPORT</a></li>
                        <li><a href="#">상품 추천</a></li>
                        <li><span>내게 맞는 상품</span></li>
                    </ul>
                </div>
            </div>
        </section>

        <section class="sub-section fitMePrdct">
            <div class="innWrap">
                <div class="sltboxWrap">
                    <div class="sltStepBox">
                        <div class="stepTit">1. 이용목적</div>
                        <ul class="stepList">
                            <li><span class="radio02"><input type="radio" name="scaleBiz1" id="A1" value="A1" v-model="bizScaleCd"> <label for="A1">10인 미만</label></span></li>
                            <li><span class="radio02"><input type="radio" name="scaleBiz1" id="A2" value="A2" v-model="bizScaleCd"> <label for="A2">50인 이상</label></span></li>
                            <li><span class="radio02"><input type="radio" name="scaleBiz1" id="A3" value="A3" v-model="bizScaleCd"> <label for="A3">100인 이상</label></span></li>
                            <li><span class="radio02"><input type="radio" name="scaleBiz1" id="A4" value="A4" v-model="bizScaleCd"> <label for="A4">300인 이상</label></span></li>
                        </ul>
                    </div>
                    <div class="sltStepBox" :class="{ nextStep: active2}">
                        <div class="stepTit">2. 업종 선택</div>
                        <ul class="stepList">
                            <li><span class="radio02"><input type="radio" name="scaleBiz2" id="B1" value="B1" v-model="bizTypeCd"> <label for="B1">공공/금융</label></span></li>
                            <li><span class="radio02"><input type="radio" name="scaleBiz2" id="B2" value="B2" v-model="bizTypeCd"> <label for="B2">글로벌</label></span></li>
                            <li><span class="radio02"><input type="radio" name="scaleBiz2" id="B3" value="B3" v-model="bizTypeCd"> <label for="B3">의료/교육</label></span></li>
                            <li><span class="radio02"><input type="radio" name="scaleBiz2" id="B4" value="B4" v-model="bizTypeCd"> <label for="B4">기타</label></span></li>
                        </ul>

                    </div>
                    <div class="sltStepBox" :class="{ nextStep: active3}">
                        <div class="stepTit">3. 사업 목적 선택</div>
                        <ul class="stepList">
                            <li><span class="radio02"><input type="radio" name="bizPurpose" id="C1" value="C1" v-model="usgTypeCd"> <label for="C1">일반 업무용</label></span></li>
                            <li><span class="radio02"><input type="radio" name="bizPurpose" id="C2" value="C2" v-model="usgTypeCd"> <label for="C2">본/지사 연결용</label></span></li>
                            <li><span class="radio02"><input type="radio" name="bizPurpose" id="C3" value="C3" v-model="usgTypeCd"> <label for="C3">기타</label></span></li>
                        </ul>
                    </div>
                </div>

                <h4 style="text-align: center;" v-show="this.result">관련 서비스 바로가기</h4>
                <div class="prdctSvc">
                    <ul>
                        <li v-show="remSvcCd == '31' || remSvcCd ==  '32' || remSvcCd ==  '34'" style="display:block;">
                            <a @click="$router.push('/goSvcPod200')">
                                <div class="img infoImg"><img src="@/assets/images/fitMePrdct01.png" alt="GiGA office 이미지"></div>
                            </a>
                            <div class="txt">
                                <h5>{{`${svcTypeNm} ${lineSpecNm == null ? '' : lineSpecNm} `}}</h5>
                                기업전용 네트워크부터<br />
                                Rack Hosting, 운용대행까지 제공하는<br />
                                ICT Total Care 서비스<br />
                            </div>
                        </li>
                        <li v-show="remSvcCd == '80'"  style="display:block;">
                            <a @click="$router.push('/goSvcPod300')">
                                <div class="img infoImg"><img src="@/assets/images/fitMePrdct02.png" alt="Kornet 이미지"></div>
                            </a>
                            <div class="txt">
                                <h5>{{`${svcTypeNm} ${lineSpecNm == null ? '' : lineSpecNm} `}}</h5>
                                국내 최대 용량의<br />
                                기업전용망을 통한<br />
                                고품질 인터넷 전용회선 서비스
                            </div>
                        </li>
                        <li v-show="remSvcCd == '90'" style="display:block;">
                            <a @click="$router.push('/goSvcPod400')">
                                <div class="img infoImg"><img src="@/assets/images/fitMePrdct03.png" alt="VPN 이미지"></div>
                            </a>
                            <div class="txt">
                                <h5>{{`${svcTypeNm} ${lineSpecNm == null ? '' : lineSpecNm} `}}</h5>
                                국내 최다 레퍼런스를 가지고<br />
                                안정성과 보안성을 보장하는 고품질<br />
                                가상 사설망 서비스
                            </div>
                        </li>
                    </ul>
                </div>
            </div>
        </section>
    </main>
    <section>
      <footerLayout></footerLayout>
    </section>
    </div>
  </body>
</template>
<script>
import HeaderLayout from './common/Header.vue'
import FooterLayout from './common/Footer.vue'

export default {
  components: {
    HeaderLayout,
    FooterLayout
  },
  data () {
    return {
      bizScaleCd: '',
      bizTypeCd: '',
      usgTypeCd: '',
      remSvcCd: '',
      svcTypeNm: '',
      lineSpecNm: '',
      active2: false,
      active3: false,

      result: ''
    }
  },
  created: function () {

  },
  mounted: function () {
    // $(function(){
    // if($('.sltboxWrap').length > 0) {
    //     $('.sltboxWrap .radio02 input:radio').on('click', function() {
    //         if($(this).parents('.sltStepBox').is(':last-child')) {
    //             $(this).parents('.sltStepBox').prev('.sltStepBox').removeClass('nextStep');
    //             $(this).parents('.sltStepBox').addClass('nextStep');
    //         } else {
    //             $('.sltStepBox').removeClass('nextStep');
    //             $(this).parents('.sltStepBox').next('.sltStepBox').addClass('nextStep');
    //             $(this).parents('.sltStepBox').nextAll('.sltStepBox').find('input:radio').removeAttr("checked");
    //         }
    //     });
    //   }
    // });
  },
  watch: {
    bizScaleCd() {
      this.active2 = true
      this.active3 = false
      if(this.bizScaleCd && this.bizTypeCd && this.usgTypeCd) {
        this.getCloudDirectCostInfo()
      }
    },
    bizTypeCd() {
      this.active2 = false
      this.active3 = true
      if(this.bizScaleCd && this.bizTypeCd && this.usgTypeCd) {
        this.getCloudDirectCostInfo()
      }
    },
    usgTypeCd() {
      if(this.bizScaleCd && this.bizTypeCd && this.usgTypeCd) {
        this.getCloudDirectCostInfo()
      }
    }
  },
  methods: {
    async getCloudDirectCostInfo () {
      try {
        const payload = {
          biz_scale_cd: this.bizScaleCd,
          biz_type_cd: this.bizTypeCd,
          usg_type_cd: this.usgTypeCd
        }
        const res = await this.$store.dispatch('gigaoffice/getRecommendedProduct', payload)
				this.result = res.response
        this.remSvcCd = this.result.rem_svc_cd
        this.svcTypeNm = this.result.svc_type_nm
        this.lineSpecNm = this.result.line_spec_nm
        console.log(this.result.rem_svc_cd)
      } catch (err) {
        console.log(err)
      }
		},
  }
}
</script>